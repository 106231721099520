
import { defineComponent } from "vue";
import TemplateTeacher from "@/views/templates/TemplateTeacher.vue";

import CourseCard from "@/components/OnlineShop/CourseCardNew.vue";
import Pagination from "@/components/Pagination.vue";

import axios from "axios";

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  components: {
    TemplateTeacher,
    CourseCard,
    Pagination,
  },
  data() {
    const productTypeMap = [null, "一對一視訊", "週課影片", "函授影片"];

    return {
      productTypeMap,
      isPendingClass: true,
      classList: [],
      type: null as any,
      tid: 0,
      currentPage: 1,
      totalPage: 0,
      total: 0,
    };
  },
  methods: {
    // 取得教師商品
    fetchTeacherProducts(page: number) {
      this.isPendingClass = true;

      axios
        .post(
          `${serverUrl}learn_shop/list`,
          {
            page: page,
            displayedNum: 20, // 每頁顯示資料數量
            type: this.type,
            teacher: [this.tid],
          },
          {
            headers: {
              Authorization: `Bearer ${loginToken}`,
            },
          }
        )
        .then((res) => {
          this.classList = res.data.data.data; // 資料
          this.currentPage = res.data.data.currentPage; // 目前頁數
          this.totalPage = res.data.data.totalPage; // 總共頁數

          // const sample = this.classList[0];
          // for (let index = 0; index < 5; index++) {
          //   this.classList.push(sample);
          // }

          this.isPendingClass = false;
        })
        .catch((err) => {
          console.log(err);
          this.isPendingClass = false;
        });
    },
    updatePage(page: number) {
      this.currentPage = page;
      this.fetchTeacherProducts(this.currentPage);
    },
  },
  mounted() {
    // 取得教師編號
    this.tid = parseInt(`${this.$route.query.tid}`);

    // 取得商品類型
    const type = this.$route.query.type;
    this.type = type;

    // 取得教師商品
    this.fetchTeacherProducts(1);
  },
});
